var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.active
    ? _c(
        "vs-popup",
        {
          attrs: { title: "Mesaj Gönder", active: _vm.active },
          on: {
            "update:active": function($event) {
              _vm.active = $event
            },
            close: _vm.close
          }
        },
        [
          _c(
            "div",
            { staticClass: "space-y-4" },
            [
              _c("div", [
                _c("strong", [_vm._v(_vm._s(_vm.userData.username))]),
                _vm._v("\n      kullanıcısına gönderilecek mesajı girin:\n    ")
              ]),
              _c("vs-textarea", {
                attrs: {
                  counter: "3000",
                  "counter-danger": _vm.counterDanger,
                  height: "300px"
                },
                on: {
                  "update:counterDanger": function($event) {
                    _vm.counterDanger = $event
                  },
                  "update:counter-danger": function($event) {
                    _vm.counterDanger = $event
                  }
                },
                model: {
                  value: _vm.message,
                  callback: function($$v) {
                    _vm.message = $$v
                  },
                  expression: "message"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "action-buttons text-right mt-8" },
            [
              _c(
                "vs-button",
                { attrs: { type: "flat" }, on: { click: _vm.close } },
                [_vm._v("\n      Vazgeç\n    ")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "ml-4",
                  attrs: { disabled: _vm.isSubmitDisabled },
                  on: { click: _vm.submit }
                },
                [_vm._v("\n      Gönder\n    ")]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }