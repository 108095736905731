<template>
  <vs-popup title="Mesaj Gönder" :active.sync="active" @close="close" v-if="active">
    <div class="space-y-4">
      <div>
        <strong>{{ userData.username }}</strong>
        kullanıcısına gönderilecek mesajı girin:
      </div>
      <vs-textarea counter="3000" :counter-danger.sync="counterDanger" v-model="message" height="300px" />
    </div>
    <div class="action-buttons text-right mt-8">
      <vs-button type="flat" @click="close">
        Vazgeç
      </vs-button>
      <vs-button class="ml-4" :disabled="isSubmitDisabled" @click="submit">
        Gönder
      </vs-button>
    </div>
  </vs-popup>
</template>

<script>
import * as _ from 'lodash-es';
import BroadcastService from '@/services/BroadcastService';
export default {
  name: 'EntryUpdate',
  props: ['isActive', 'user'],
  data() {
    return {
      active: false,
      userData: {},
      message: '',
      counterDanger: false
    };
  },
  computed: {
    isSubmitDisabled() {
      return this.message.length < 1 || this.message.length > 3000;
    }
  },

  methods: {
    init() {
      this.message = '';
    },
    close() {
      this.$emit('close', false);
    },
    async submit() {
      if (!confirm('Mesajı göndermek istedeğinize emin misiniz?')) {
        return;
      }
      try {
        let userId = this.userData.userId;
        if (this.userData.hasOwnProperty('onedio_user_id')) {
          userId = this.userData.onedio_user_id;
        }
        const payload = {
          message: this.message,
          userId
        };
        await BroadcastService.sendMessage(payload);
        this.$vs.notify({
          color: 'success',
          text: 'Başarıyla gönderildi.'
        });
        this.close();
      } catch (e) {
        this.$vs.notify({
          color: 'danger',
          text: 'İşlem başarısız. Lütfen tekrar deneyin.'
        });
      }
    }
  },
  watch: {
    isActive(val) {
      this.active = val;
    },
    user(val) {
      this.userData = _.cloneDeep(val);
      this.init(); // popup zaten mounted oldugu icin
    }
  }
};
</script>
<style>
.vs-textarea {
  height: 100% !important;
}
.vs-tooltip {
  z-index: 55000;
}
</style>
